export default {
  methods: {
    swallSuccess(title, text, confirmButtonText) {
      this.showSwall('success', title, text, 'question', confirmButtonText)
    },

    swallError(title, text, confirmButtonText) {
      this.showSwall('danger', title, text, 'warning', confirmButtonText)
    },

    showSwall(type, title, text, icon, confirmButtonText) {
      this.$swal({
        title,
        text,
        icon,
        showCancelButton: false,
        confirmButtonText,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
