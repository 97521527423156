export default {
  // Endpoints
  project: 'crm',
  domain: 'http://127.0.0.1:8081',
  api: process.env.VUE_APP_CRM_API,
  loginEndpoint: `${process.env.VUE_APP_CRM_API}/login`,
  refreshEndpoint: `${process.env.VUE_APP_CRM_API}/refresh`,
  registerEndpoint: `${process.env.VUE_APP_CRM_API}/register`,
  logoutEndpoint: `${process.env.VUE_APP_CRM_API}/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'crmAccessToken',
  storageTokenAt: 'crmAccessTokenAt',
  storageRefreshTokenKeyName: 'crmRefreshToken',
}
