/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import crmJwt from '@/auth/jwt/crmJwt'

export default class CrmService {
  /*
   * ACCOUNT
   */
  static showAccount(accountId) {
    return crmJwt.get(`/account/${accountId}`)
  }

  static putAccount(data) {
    return crmJwt.put('/account', {}, data)
  }

  static postAccount(data) {
    return crmJwt.post('/account', {}, data)
  }

  static listAccounts(filters) {
    return crmJwt.get('/accounts', filters)
  }

  static removeAccount(accountId) {
    return crmJwt.delete(`/account/${accountId}`)
  }

  static addUserToAccount(accountId, email, permissionType) {
    return crmJwt.post('/account-add-user', {}, { account_id: accountId, email, permission_type: permissionType })
  }

  static changeUserToAccount(accountId, userId, permissionType) {
    return crmJwt.post('/account-change-user', {}, { account_id: accountId, user_id: userId, permission_type: permissionType })
  }

  static removeAccountUser(accountId, userId) {
    return crmJwt.post('/account-remove-user', {}, { account_id: accountId, user_id: userId })
  }

  /*
   * CLIENT
   */
  static listClients(filters) {
    return crmJwt.get('/clients', filters)
  }

  static showClient(clientId, args) {
    return crmJwt.get(`/client/${clientId}`, args ?? [])
  }

  static removeClient(clientId) {
    return crmJwt.delete(`/client/${clientId}`)
  }

  /*
   * USER
   */
  static listUsers(filters) {
    return crmJwt.get('/users', filters)
  }

  static putUser(data) {
    return crmJwt.put('/user', {}, data)
  }

  static postUser(data) {
    return crmJwt.post('/user', {}, data)
  }

  static putClient(data) {
    return crmJwt.put('/client', {}, data)
  }

  static postClient(data) {
    return crmJwt.post('/client', {}, data)
  }

  static listUsersFromAccount(accountId) {
    return crmJwt.get('/users', { account_id: accountId })
  }

  static listClientsFromAccount(accountId) {
    return crmJwt.get('/users', { client_account_id: accountId })
  }

  static showUser(userId, args) {
    return crmJwt.get(`/user/${userId}`, args ?? [])
  }

  static removeUser(userId) {
    return crmJwt.delete(`/user/${userId}`)
  }

  static copyUser(userId) {
    return crmJwt.post(`/user/copy/${userId}`)
  }

  static getUser(id) {
    return crmJwt.get(`/client/${id}`)
  }

  /*
   * Products
   */
  static listProducts(filters) {
    return crmJwt.get('/products', filters ?? {})
  }

  /*
   * Teams
   */
  static listTeams(filters) {
    return crmJwt.get('/teams', filters ?? {})
  }

  static postTeam(data) {
    return crmJwt.post('/team', {}, data)
  }

  static removeTeam(teamId) {
    return crmJwt.delete(`/team/${teamId}`)
  }

  static putTeam(data) {
    return crmJwt.put('/team', {}, data)
  }

  static postTeamUser(data) {
    return crmJwt.post('/team-add-user', {}, data)
  }

  static deleteTeamUser(data) {
    return crmJwt.delete('/team-remove-user', data)
  }

  /*
   * Negotiations
   */
  static listNegotiations(filters) {
    return crmJwt.get('/negotiations', filters ?? {})
  }
}
