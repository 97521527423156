// import useJwt from '@core/auth/jwt/useJwt'
// import axios from '@axios'
import JwtService from '@/services/jwtService'
import axios from 'axios'
import crmJwtDefaultConfig from './crmJwtDefaultConfig'

const axiosCrm = axios.create({
})

const jwt = new JwtService(axiosCrm, crmJwtDefaultConfig)
export default jwt
