<template>
  <section id="agendador">
    <b-breadcrumb
      v-if="showFilters"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="showFilters"
      ref="filters"
      title="Filtros"
      :title-collapse="true"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Nome da Conta"
            label-for="account"
          >
            <b-form-input
              id="name"
              v-model="filtersValues.account"
              @keyup.enter="listAccounts"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Nome do dono da conta"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="filtersValues.owner_name"
              @keyup.enter="listAccounts"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="E-mail do dono da conta"
            label-for="email"
          >
            <b-form-input
              id="name"
              v-model="filtersValues.owner_email"
              @keyup.enter="listAccounts"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-buscar"
              variant="primary"
              @click="searchAndResetPage"
            >
              Buscar
            </b-button>

            <b-button
              id="btn-limpar"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card-actions
      ref="accounts"
      title="Contas"
      :title-collapse="true"
      class="form-filters"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="true"
      :collapsed="false"
      @refresh="listAccounts"
    >
      <b-row
        v-if="userId === null"
        class="header-card"
        align-v="center"
      >
        <b-col class="text-right">
          <b-button
            class="mb-2"
            :to="{ name: 'crm-account-edit', params: { id: 0 } }"
          >
            Nova Conta
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            :busy="isLoading || isRemoving"
            fixed
            :items="results"
            :fields="resultFields"
            sort-icon-left
            no-local-sorting
          >
            <template #head(name)>
              Conta
            </template>

            <template #head(owner)>
              Dono
            </template>

            <template #head(users)>
              Usuários
            </template>

            <template #head(actions)>
              Ações
            </template>

            <template #head(permission_type)>
              Permissão
            </template>

            <template #cell(name)="row">
              <span>{{ row.item.name }}</span><br>
            </template>

            <template #cell(owner)="row">
              <span>{{ row.item.owner }}</span><br>
            </template>

            <template #cell(permission_type)="row">
              <span>{{ $t(`crm.account.permission_type.${row.item.permission_type}`) }}</span>
            </template>

            <template #cell(users)="row">
              <span
                v-for="user in row.item.users"
                :key="user.id"
              >{{ user.name }}<br></span>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  :to="{ name: 'crm-account-edit', params: { id: row.item.id } }"
                  :disabled="isLoading || isRemoving"
                  title="Editar"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>

                <b-button
                  variant="flat-primary"
                  :title="userId ? 'Desvincular' : 'Remover'"
                  :disabled="isLoading || isRemoving"
                  @click="remove(row.item)"
                >
                  <feather-icon
                    :icon="userId ? 'DeleteIcon' : 'TrashIcon'"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page"
            :link-gen="() => { return '#' }"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BPaginationNav,
  BRow,
  BTable,
} from 'bootstrap-vue'
import CrmService from '@/services/crmService'
import toasts from '@/mixins/toast'
import swall from '@/mixins/swall'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BFormGroup,
    BFormInput,
    BPaginationNav,
    BButton,
    BTable,
    BRow,
    BCol,
    BBreadcrumb,
  },

  mixins: [toasts, swall],

  props: {
    showFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    userId: {
      type: Number,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'CRM',
          active: true,
        },
        {
          text: 'Contas',
          to: { name: 'crm-account-list' },
        },
      ],
      filtersValues: {
        owner_name: '',
        owner_email: '',
        account: '',
      },
      pages: {},
      currentPage: 1,
      results: [],
      resultFields: [
        { key: 'name', sortable: false },
        { key: 'owner', sortable: false },
        { key: 'users', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isRemoving: false,
    }
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    if (this.userId) {
      this.resultFields = [
        { key: 'name', sortable: false },
        { key: 'permission_type', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ]
    }

    this.listAccounts()
  },

  methods: {
    reset() {
      Object.entries(this.filtersValues).forEach(item => {
        this.filtersValues[item[0]] = ''
      })
      this.listAccounts()
    },

    searchAndResetPage() {
      this.currentPage = 1
      this.listAccounts()
    },

    remove(item) {
      if (this.userId) {
        return this.removeAccountUser(item)
      }
      return this.removeAccount(item)
    },

    removeAccountUser(item) {
      this.$swal({
        title: 'Desvincular usuário da conta?',
        text: `Deseja desvincular este usuário da conta '${item.name}'? O usuário continuará existindo, apenas não estará mais vinculado a esta conta!`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, desvincular!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeAccountUser(item.id, this.userId).then(response => {
            if (response.data.deleted) {
              this.listAccounts()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    removeAccount(item) {
      this.$swal({
        title: 'Deletar conta?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          CrmService.removeAccount(item.account_id).then(response => {
            if (response.data.deleted) {
              this.listAccounts()
            }
          }).catch(error => {
            this.swallError('Erro!', error.response.data.message, 'Fechar')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      })
    },

    listAccounts() {
      this.isLoading = true
      if (this.userId) {
        this.filtersValues.user_id = this.userId
      }

      CrmService.listAccounts(this.filtersValues).then(response => {
        if (response.data !== null) {
          this.results = response.data.accounts.data.map(m => {
            const owner = m.users.find(f => f.pivot.permission_type === 'owner')
            return {
              ...m,
              owner: owner ? owner.name : '',
              users: m.users.map(user => ({ name: user.name, id: user.id })),
            }
          })
          this.pages = {
            ...response.data.accounts,
            data: null,
          }
        }
      }).finally(() => {
        this.isLoading = false
        this.$refs.accounts.showLoading = false
      })
    },
  },
}
</script>
